import { Routes, Route, Navigate } from 'react-router-dom';

import {
  Home,
  Blog,
  Post,
  CommercialRefrigeration,
  About,
  Contact,
  SingleService,
  // Coupons,
  BookNow,
  Page404,
  ServiceAreas,
} from './pages';

export const ADMIN_URL = 'https://admin.prothermalcali.com/wp-json/wp/v2';

const App = () => (
  <Routes>
    <Route path="*" element={<Navigate replace to="/404" />} />
    <Route path="/home" element={<Navigate replace to="/" />} />

    <Route path="/" element={<Home />} />

    <Route path="/company/blog" element={<Blog />} />
    <Route path="/company/blog/:slug" element={<Post />} />
    <Route path="/company/about" element={<About />} />

    <Route path="/commercial-refrigeration-services" element={<CommercialRefrigeration />} />
    <Route path="/commercial-refrigeration-services/:slug" element={<SingleService />} />

    {/* <Route path="/company/special-offers" element={<Coupons />} /> */}

    <Route path="/service-areas" element={<ServiceAreas />} />

    <Route path="/contact" element={<Contact />} />

    <Route path="/book-now" element={<BookNow />} />

    <Route path="/404" element={<Page404 />} />

    {/* <Route path="/privacy-policy" element={<LegalPage />} /> */}
    {/* <Route path="/terms-of-use" element={<LegalPage />} /> */}
    {/* <Route path="/accessibility-statement" element={<LegalPage />} /> */}
  </Routes>
);

export default App;
