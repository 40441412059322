import useFetch from 'react-fetch-hook';

import { ADMIN_URL } from '../App';

import { Layout, Seo, Spinner } from '../components';

import { CommonHero, AboutService, ServicesDescription, CallToAction, WhyChooseUs, CallUs, FAQ } from '../sections';

const AirConditioning = () => {
  const { data, error, isLoading } = useFetch(`${ADMIN_URL}/pages?slug=commercial-refrigeration-services`);

  if (isLoading) return <Spinner />;

  return (
    <Layout id="services">
      <Seo data={data[0]?.yoast_head_json} />
      <CommonHero title="Commercial Refrigeration Services" buttons />
      <AboutService data={data[0]?.acf} />
      <ServicesDescription data={data[0]?.acf.services} />
      <CallToAction />
      <WhyChooseUs data={data[0]?.acf.why_choose_us} />
      <FAQ data={data[0]?.acf.faq} />
      <CallUs />
    </Layout>
  );
};

export default AirConditioning;
