import './ourValue.scss';

const OurValue = ({ data }) => (
  <section className="our-value">
    <div className="container">
      <h2 className="section-title">Our Values</h2>

      <div className="our-value__inner">
        <div className="grid">
          {data.map((el, index) => (
            <div className="grid__item" key={index}>
              <div className="icon">
                <img src={el.icon} alt={el.heading} />
              </div>
              <h4>{el.heading}</h4>
              <p>{el.text}</p>
            </div>
          ))}
        </div>

        <div className="image">
          <img src="../assets/images/our-value.png" alt="Our Values" />
        </div>
      </div>
    </div>
  </section>
);

export default OurValue;
