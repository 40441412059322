import { Link } from 'react-router-dom';

import './services.scss';

const Services = ({ data }) => (
  <section className="home-services">
    <div className="container">
      <h4 className="section-title">{data.title}</h4>

      <div className="home-services__list">
        {data.services.map((el, index) => (
          <div className="service-card" key={index}>
            <div className="row">
              <div className="icon">
                <img src={el.icon} alt={el.title} />
              </div>

              <h4>{el.title}</h4>
            </div>

            <p>{el.description}</p>

            <Link to="/book-now" className="btn blue">
              Book Now
            </Link>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Services;
