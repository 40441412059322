import { Link } from 'react-router-dom';

import './menu.scss';

const AdditionalMenu = () => {
  return (
    <div className="additional-menu">
      <Link to="/book-now">
        <img src="../../assets/images/services/installation.svg" alt="Installation" /> <span>Installation</span>
      </Link>

      <Link to="/book-now">
        <img src="../../assets/images/services/replacement.svg" alt="Replacement" />
        <span>Replacement</span>
      </Link>

      <Link to="/book-now">
        <img src="../../assets/images/services/repair.svg" alt="Repair" />
        <span>Repair</span>
      </Link>

      <Link to="/book-now">
        <img src="../../assets/images/services/maintenance.svg" alt="Maintenance" />
        <span>Maintenance</span>
      </Link>
    </div>
  );
};

export default AdditionalMenu;
