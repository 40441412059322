import './areas.scss';

const Areas = ({ data }) => (
  <section className="areas">
    <div className="container">
      <div className="areas__inner">
        <div>
          <h4 className="section-title">
            Areas We
            <br /> Serve
          </h4>
          <img src="./assets/images/decor/home-areas_1.svg" alt="Areas" className="decor1" />
          <img src="./assets/images/decor/home-areas_2.svg" alt="Areas 2" className="decor2" />
          <img src="./assets/images/decor/home-areas_3.svg" alt="Areas 2" className="decor3" />
        </div>

        <ul>
          {data.map((el, index) => (
            <li key={index}>{el.location}</li>
          ))}
        </ul>
      </div>
    </div>
  </section>
);

export default Areas;
