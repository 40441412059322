import { Link } from 'react-router-dom';

import './error404.scss';

const Error404 = () => (
  <section className="error404">
    <div className="container">
      <h1>Error 404</h1>

      <p>Page Not Found</p>

      <Link to="/" className="btn red">
        Home Page
      </Link>
    </div>
  </section>
);

export default Error404;
