import { Link } from 'react-router-dom';

import './hero.scss';

const HomeHero = ({ data }) => (
  <section className="home-hero">
    <div className="container">
      <div className="home-hero__inner" style={{ backgroundImage: `url(${data.image})` }}>
        <div className="page-title">{data.title}</div>
        <span className="subtitle" dangerouslySetInnerHTML={{ __html: data.subtitle }} />

        <Link to="/book-now" className="btn red">
          Book Now
        </Link>

        <div className="home-hero__labels">
          {data.labels.map((el, index) => (
            <div key={index}>
              <p>{el.number}</p>
              <p>{el.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default HomeHero;
