import useFetch from 'react-fetch-hook';

import { ADMIN_URL } from '../App';

import { Layout, Seo, Spinner } from '../components';

import { CommonHero, PostsGrid } from '../sections';

const CATEGORIES_URL = 'https://admin.prothermalcali.com/wp-json/wp/v2/categories?per_page=100';

const Blog = () => {
  const { data, error, isLoading } = useFetch(`${ADMIN_URL}/posts?per_page=100`);
  const categories = useFetch(CATEGORIES_URL);

  if (isLoading || categories.isLoading) return <Spinner />;

  return (
    <Layout>
      <Seo
        data={{
          title: 'Blog | ProThermal',
          description: `Explore ProThermal's commercial refrigeration services blog for expert insights. Discover solutions to keep your business running smoothly.`,
        }}
      />
      <CommonHero title="Blog" />
      <PostsGrid data={data} categories={categories?.data} />
    </Layout>
  );
};

export default Blog;
