import useFetch from 'react-fetch-hook';

import { ADMIN_URL } from '../App';

import { Layout, Seo, Spinner } from '../components';

import { CommonHero, AboutService, WhyChooseUs, CallUs, Areas } from '../sections';

const ServiceAreas = () => {
  const { data, error, isLoading } = useFetch(`${ADMIN_URL}/pages?slug=service-areas`);

  if (isLoading) return <Spinner />;

  return (
    <Layout>
      <Seo data={data[0]?.yoast_head_json} />
      <CommonHero title="Service Areas" />
      <AboutService data={data[0]?.acf} />

      <WhyChooseUs data={data[0]?.acf.why_choose_us} />

      <Areas data={data[0]?.acf?.areas} />
      <CallUs />
    </Layout>
  );
};

export default ServiceAreas;
