import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import './faq.scss';

const FAQ = ({ data }) => {
  return (
    <section className="faq" id="faq">
      <div className="container">
        <div className="faq__inner">
          <h2 className="section-title">Frequently Asked Questions</h2>

          <div className="faq__questions">
            <Accordion>
              {data.map((item, index) => (
                <AccordionItem key={index}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      {item.question}

                      <div className="icon" />
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>{item.answer}</AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
