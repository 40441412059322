import { useParams } from 'react-router-dom';

import useFetch from 'react-fetch-hook';

import { ADMIN_URL } from '../App';

import { Layout, Seo, Spinner } from '../components';

import { CommonHero, AboutService, ServicesDescription, CallToAction, WhyChooseUs, CallUs, FAQ } from '../sections';

const SingleService = () => {
  const { slug } = useParams();
  const { data, error, isLoading } = useFetch(`${ADMIN_URL}/pages?slug=${slug}`);

  if (isLoading) return <Spinner />;

  return (
    <Layout id="services">
      <Seo data={data[0]?.yoast_head_json} />
      <CommonHero title={data[0]?.title.rendered} buttons />
      <AboutService data={data[0]?.acf} button={true} title={data[0]?.title.rendered} />
      <CallToAction />
      <ServicesDescription data={data[0]?.acf.info} button={false} />
      <WhyChooseUs data={data[0]?.acf.why_choose_us} />
      <FAQ data={data[0]?.acf.faq} />
      <CallUs />
    </Layout>
  );
};

export default SingleService;
