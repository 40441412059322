import { Link } from 'react-router-dom';

import './servicesDescription.scss';

const ServicesDescription = ({ data, button = true }) => (
  <section className="services-description">
    <div className="container">
      <div className="services-description__inner">
        {data.map((el, index) => (
          <div className="services-description__item" key={index}>
            <div className="services-description__item--content">
              <h3>{el.title}</h3>

              <img src={el.image} alt={el.title} className="mobile-image" />

              <div dangerouslySetInnerHTML={{ __html: el.description }} />

              {button && (
                <Link to="/book-now" className="btn red">
                  Book Now
                </Link>
              )}
            </div>

            <div className="services-description__item--image">
              <img src={el.image} alt={el.title} />
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default ServicesDescription;
