import { Link } from 'react-router-dom';
import moment from 'moment';
import Slider from 'react-slick';

import { sliderSettings } from '../Reviews/Reviews';

import './articles.scss';

const Articles = ({ data }) => (
  <section className="articles">
    <div className="container">
      <h4 className="section-title">Articles and News</h4>

      <Slider className="articles__list" {...sliderSettings}>
        {data?.map((item) => (
          <ArticleCard {...{ post: item, key: item.id }} />
        ))}
      </Slider>
    </div>
  </section>
);

export default Articles;

const ArticleCard = ({ post }) => (
  <Link className="article-card" to={`/company/blog/${post.slug}`}>
    <div className="article-card__image">
      <img src={post.acf.image} alt={post.title.rendered} />
    </div>
    <div className="article-card__info">
      <span>{moment(post.date).format('MM/DD/YYYY')}</span>

      <h3>{post.title.rendered}</h3>

      <p className="btn red">Read More</p>
    </div>
  </Link>
);
