import './whyChooseUs.scss';

const WhyChooseUs = ({ data }) => (
  <section className="why-choose-us" id="why-choose-us">
    <div className="container">
      <h2 className="section-title">{data.title}</h2>

      <div className="counter">
        {data.counter.map((el, index) => (
          <div className="counter__item" key={index}>
            <p>{el.number}</p>
            <p>{el.title}</p>
          </div>
        ))}
      </div>

      <div className="reasons">
        {data.reasons.map((el, index) => (
          <div className="reasons__item" key={index}>
            <div className="reasons__item--icon">
              <img src={el.icon} alt={el.title} />
            </div>
            <h4>{el.heading}</h4>
            <p>{el.description}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default WhyChooseUs;
