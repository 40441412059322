import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';

import './post.scss';

const BlogPost = ({ post }) => {
  const submit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    try {
      const response = await axios.post(
        'https://admin.prothermalcali.com/wp-json/contact-form-7/v1/contact-forms/8/feedback',
        data,
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        event.target.reset();
      }
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please, try again!');
      console.log(error);
    }
  };

  return (
    <section className="post">
      <div className="container">
        <h1>{post?.title.rendered}</h1>

        <div className="post__inner">
          <div className="content">
            <div className="content__image">
              <img src={post?.acf?.image} alt={post?.title.rendered} />
            </div>

            <div className="content__meta">
              <span>{moment(post?.date).format('MM/DD/YYYY')}</span>
            </div>

            <div className="content__text" dangerouslySetInnerHTML={{ __html: post?.content.rendered }} />
          </div>

          <div className="sidebar">
            <div className="post-form">
              <h4>Keep in touch with us</h4>
              <form onSubmit={submit}>
                <input name="customer_name" type="text" placeholder="Full Name" required />
                <input name="customer_phone" type="tel" placeholder="Phone Number" required />
                <input name="customer_email" type="email" placeholder="Email Address" required />
                <select name="type_of_service">
                  <option hidden>Choose Service</option>
                  <option value="A/C Installation">A/C Installation</option>
                  <option value="A/C Maintenance">A/C Maintenance</option>
                  <option value="A/C Repair">A/C Repair</option>
                  <option value="Furnace Services">Furnace Services</option>
                  <option value="Heat Pump Services">Heat Pump Services</option>
                  <option value="Heating Installation">Heating Installation</option>
                  <option value="Heating Maintenance">Heating Maintenance</option>
                  <option value="Heating Repair">Heating Repair</option>
                  <option value="Indoor Air Quality">Indoor Air Quality</option>
                </select>
                <textarea name="message" placeholder="Please let us know if you have any special requests" />

                <input type="submit" value="Send" className="btn blue-border" />

                <div className="data-protection-label">
                  <svg
                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="LockIcon"
                  >
                    <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path>
                  </svg>

                  <p>Data protected and privacy</p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogPost;
