import axios from 'axios';
import { toast } from 'react-toastify';

import './contacts.scss';

const Contacts = ({ data }) => {
  const submit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    try {
      const response = await axios.post(
        'https://admin.prothermalcali.com/wp-json/contact-form-7/v1/contact-forms/8/feedback',
        data,
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        event.target.reset();
      }
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please, try again!');
      console.log(error);
    }
  };

  return (
    <section className="contacts">
      <div className="container">
        <div className="contacts__inner">
          <div className="contacts__form">
            <h4>Keep in touch with us</h4>
            <form onSubmit={submit}>
              <input name="customer_name" type="text" placeholder="Full Name" required />
              <input name="customer_phone" type="tel" placeholder="Phone Number" required />
              <input name="customer_email" type="email" placeholder="Email Address" required />
              <select name="type_of_service">
                <option hidden>Choose Service</option>
                <option value="Walk-In Coolers and Freezers">Walk-In Coolers and Freezers</option>
                <option value="Cold Storage Rooms">Cold Storage Rooms</option>
                <option value="Blast Chillers and Freezers">Blast Chillers and Freezers</option>
                <option value="Reach-In Refrigerators and Freezers">Reach-In Refrigerators and Freezers</option>
                <option value="Refrigerated Prep Tables">Refrigerated Prep Tables</option>
                <option value="Display Refrigerators">Display Refrigerators</option>
                <option value="Ice Machines">Ice Machines</option>
                <option value="Wine Coolers">Wine Coolers</option>
                <option value="Medical Refrigerators & Freezers">Medical Refrigerators & Freezers</option>
              </select>
              <textarea
                name="message"
                placeholder="Please let us know if you have any special requests
"
              />

              <input type="submit" value="Send" className="btn blue" />

              <div className="data-protection-label">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="LockIcon"
                >
                  <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path>
                </svg>

                <p>Data protected and privacy</p>
              </div>
            </form>
          </div>

          <div className="contacts__info">
            <p className="address">{data.address}</p>
            <a href={`tel:${data.phone}`} className="phone">
              {data.phone}
            </a>
            <a href={`mailto:${data.email}`} className="email">
              {data.email}
            </a>
            <p className="hours">{data.working_hours}</p>
          </div>
        </div>
      </div>
      <div className="contacts__map" dangerouslySetInnerHTML={{ __html: data.map }} />
    </section>
  );
};

export default Contacts;
