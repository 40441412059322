import { Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';

import contacts from '../../static/contacts';

import './footer.scss';

const Footer = ({ menu }) => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="page-footer">
      <div className="container">
        <div className="page-footer__inner">
          <div className="page-footer__contacts">
            <Link to="/home" className="logo">
              <img src="../../assets/images/logo/prothermal.svg" alt="ProThermal" />
            </Link>

            <p className="address">{contacts.address}</p>

            <span className="email">
              <Link to={`mailto:${contacts.email}`}>{contacts.email}</Link>
            </span>

            <span className="phone">
              <Link to={`tel:${contacts.phone}`}>{contacts.phone}</Link>
            </span>

            <div className="social-media">
              <p>Follow Us On</p>

              <ul className="social-media__icons">
                {/* <li>
                  <Link to="https://fb.com" target="_blank">
                    <img src="../../assets/images/fb.svg" alt="fb" />
                  </Link>
                </li> */}

                <li>
                  <Link to="https://www.yelp.com/biz/freon-hvac-san-jose" target="_blank">
                    <img src="../../assets/images/yelp.svg" alt="yelp" />
                  </Link>
                </li>

                <li>
                  <Link
                    to="https://www.google.com/search?q=ProThermal+llc&stick=H4sIAAAAAAAA_-NgU1I1qLAwsEg1NkxMSkk2SrVMTTS3MqhITjRIs0hOsUxMNjcytDQ0WcTKF1CUH5KRWpSbmKOQk5MMALY6V706AAAA&hl=en&mat=CWnOTaZiObuvElcBpsFAC-kiu_2aw-KJRY8Rg5q_cWg9SIncostbTkifFGkUsNKPARJTZPQah0Ekh7hdSXOPyZhvS3scyWGx6r4PzQ9bSGTAzD72u_11DaucTKqdz1X5gR4&authuser=0"
                    target="_blank"
                  >
                    <img src="../../assets/images/google.svg" alt="google" />
                  </Link>
                </li>

                <li>
                  <Link to="https://www.youtube.com/@freoninc" target="_blank">
                    <img src="../../assets/images/youtube.svg" alt="youtube" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="footer-nav">
            {menu?.items.slice(0, 4).map((el) => (
              <Fragment key={el.ID}>
                <NavLink to={`/${el.slug}`} className="footer-nav__item">
                  {el.title}
                </NavLink>
                {el.child_items && (
                  <div className="submenu">
                    {el.child_items.map((c) => (
                      <div className="footer-nav__item--subitem" key={c.ID}>
                        <NavLink to={`/${el.slug}/${c.slug}`} className="footer-nav__subitem">
                          {c.title}
                        </NavLink>
                      </div>
                    ))}
                  </div>
                )}
              </Fragment>
            ))}
          </div>

          <div className="footer-nav">
            {menu?.items.slice(4, menu?.items.length).map((el) => (
              <Fragment key={el.ID}>
                <NavLink to={`/${el.slug}`} className="footer-nav__item">
                  {el.title}
                </NavLink>
                {el.child_items && (
                  <div className="submenu">
                    {el.child_items.map((c) => (
                      <div className="footer-nav__item--subitem" key={c.ID}>
                        <NavLink to={`/${el.slug}/${c.slug}`} className="footer-nav__subitem">
                          {c.title}
                        </NavLink>
                      </div>
                    ))}
                  </div>
                )}
              </Fragment>
            ))}

            <div className="hours">
              <p>Working Hours</p>
              <p>8:00 AM – 8:00 PM every day</p>
            </div>
          </div>
        </div>

        <div className="underline">
          <p>©{currentYear} ProThermal Commercial Refrigeration. All Rights Reserved.</p>

          <a href="http://toporin.com/" target="_blank" rel="noReferrer">
            Designed by <strong>Toporin Studio®</strong> in California
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
