import useFetch from 'react-fetch-hook';

import { ADMIN_URL } from '../App';

import { Layout, Seo, Spinner } from '../components';

import {
  HomeHero,
  Services,
  About,
  AboutServices,
  CallToAction,
  Offers,
  Reviews,
  Areas,
  Brands,
  CallUs,
  Awards,
  Articles,
  ContactOurSpecialists,
} from '../sections';

const Home = () => {
  const { data, error, isLoading } = useFetch(`${ADMIN_URL}/pages?slug=home`);

  const posts = useFetch(`${ADMIN_URL}/posts?per_page=100`);

  if (isLoading || posts.isLoading) return <Spinner />;

  return (
    <Layout>
      <Seo data={data[0]?.yoast_head_json} />
      <HomeHero data={data[0]?.acf?.hero_section} />
      <Services data={data[0]?.acf?.services_section} />
      <About data={data[0]?.acf?.about_us_section} />
      <AboutServices data={data[0]?.acf?.about_services_section} />
      {/* <CallToAction /> */}
      {/* <Offers data={data[0]?.acf?.offers_and_savings_section} /> */}
      <Reviews data={data[0]?.acf?.reviews} />
      <ContactOurSpecialists />
      <Areas data={data[0]?.acf?.areas} />
      {/* <Brands data={data[0]?.acf?.brands} /> */}
      <CallUs />
      {/* <Awards /> */}
      <Articles data={posts.data} />
    </Layout>
  );
};

export default Home;
