import { useParams } from 'react-router-dom';
import useFetch from 'react-fetch-hook';

import { ADMIN_URL } from '../App';

import { Layout, Seo, Spinner } from '../components';

import { BlogPost } from '../sections';

const Post = () => {
  const { slug } = useParams();
  const { data, error, isLoading } = useFetch(`${ADMIN_URL}/posts?slug=${slug}`);

  const posts = useFetch(`${ADMIN_URL}/posts?per_page=100`);

  if (isLoading || posts.isLoading) return <Spinner />;

  return (
    <Layout>
      <Seo data={data[0]?.yoast_head_json} />

      <BlogPost post={data[0]} />
    </Layout>
  );
};

export default Post;
