import { Layout } from '../components';

import { Error404 } from '../sections';

const Page404 = () => (
  <Layout>
    <Error404 />
  </Layout>
);

export default Page404;
