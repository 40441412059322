import { Layout } from '../components';

import { BookingForm } from '../sections';

const BookNow = () => {
  return (
    <Layout>
      <BookingForm />
    </Layout>
  );
};

export default BookNow;
