import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import './postsGrid.scss';

const PostsGrid = ({ data, categories }) => {
  const [postToShow, setPostsToShow] = useState(data);
  const [category, setCategory] = useState('All');

  const handleChangeCategory = (name) => setCategory(name);

  useEffect(() => {
    const currentCategory = categories?.find((el) => el.name === category)?.id;

    if (currentCategory !== undefined) setPostsToShow(data?.filter((el) => el.categories.includes(currentCategory)));
    else setPostsToShow(data);
  }, [category]);

  return (
    <section className="blog">
      <div className="container">
        <div className="categories">
          <div
            className={`categories--item ${category === 'All' ? 'active' : null}`}
            onClick={() => handleChangeCategory('All')}
          >
            All
          </div>

          {categories?.map((el) => (
            <div
              className={`categories--item ${category === el.name ? 'active' : null}`}
              key={el.id}
              onClick={() => handleChangeCategory(el.name, el.id)}
            >
              {el.name}
            </div>
          ))}
        </div>

        <div className="blog__posts">
          {postToShow.map((post) => (
            <Link className="post-card" key={post.id} to={`/company/blog/${post.slug}`}>
              <div className="post-card__image">
                <img src={post.acf.image} alt={post.title.rendered} />
              </div>
              <div className="post-card__info">
                <span>{moment(post.date).format('MM/DD/YYYY')}</span>

                <h3>{post.title.rendered}</h3>

                <div dangerouslySetInnerHTML={{ __html: post.content.rendered.slice(0, 160) }} className="content" />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PostsGrid;
