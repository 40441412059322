import { Link } from 'react-router-dom';

import contacts from '../../static/contacts';

import './callUs.scss';

const CallUs = () => (
  <section className="call-us">
    <div className="container">
      <div className="call-us__inner">
        <div className="call-us__content">
          <h4 className="section-title">Contact ProThermal to Schedule Commercial Refrigeration Services</h4>

          <div className="links">
            <Link to="/book-now" className="btn blue">
              Book Now
            </Link>

            <span>
              or call us <Link to={`tel:${contacts.phone}`}>{contacts.phone}</Link>
            </span>
          </div>
        </div>
        <div className="call-us__image">
          <img src="../assets/images/call-us.png" alt="Call Us" />
        </div>
      </div>
    </div>
  </section>
);

export default CallUs;
