import { Link } from 'react-router-dom';

import './hero.scss';

const CommonHero = ({ title, buttons = false }) => {
  const handleClickScroll = (id, yOffset = -150) => {
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  return (
    <section className="common-hero">
      <div className="container">
        <div className="common-hero__inner">
          <h1 className="section-title">{title}</h1>

          <div className="block-footer">
            <img src="../assets/images/decor/common-hero.svg" alt={title} />

            {buttons && (
              <div className="navigation">
                <Link to="/book-now" className="btn red">
                  Get Estimate
                </Link>
                <Link to="/contact" className="btn red">
                  Contact Us
                </Link>

                <button onClick={() => handleClickScroll('why-choose-us')} className="btn">
                  Why Us
                </button>

                <button onClick={() => handleClickScroll('faq')} className="btn">
                  FAQ
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommonHero;
