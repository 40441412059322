import { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';

import contacts from '../../static/contacts';

import './header.scss';

const Header = ({ menu }) => {
  const [shadow, setShadow] = useState(false);
  const [open, setOpen] = useState(false);
  const location = window.location.href.split('/');

  const menuToggle = () => setOpen(!open);

  const handleScroll = () => setShadow(window.scrollY > 0);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!menu) return;

  return (
    <header className={`page-header ${shadow && 'shadow'}`}>
      <div className="container">
        <Link to="/" className="header-logo">
          <img src="../../assets/images/logo/prothermal.svg" alt="ProThermal" />
        </Link>

        <Link to={`tel:${contacts.phone}`} className="btn red mobile-phone">
          {contacts.phone}
        </Link>

        <Burger onClick={menuToggle} />

        <nav className={open ? 'open' : null}>
          <CloseButton onClick={menuToggle} />

          <ul className="nav-menu">
            {menu?.items?.map((item) => (
              <li key={item.ID}>
                {item.child_items ? (
                  <div className="nav-submenu">
                    {item.slug !== 'company' ? (
                      <span>
                        <NavLink to={`/${item.slug}`}>{item.title}</NavLink>
                      </span>
                    ) : (
                      <span className={location[3] === item.slug ? 'active' : null}>{item.title}</span>
                    )}
                    <ul>
                      {item.child_items.map((subitem, index) => (
                        <li key={index}>
                          <NavLink to={`/${item.slug.toLowerCase()}/${subitem.slug}`}>{subitem.title}</NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <NavLink to={`/${item.slug === undefined ? '' : item.slug}`}>{item.title}</NavLink>
                )}
              </li>
            ))}
          </ul>
        </nav>

        <div className="header-buttons">
          <Link to="/book-now" className="btn blue">
            Book Now
          </Link>

          <Link to={`tel:${contacts.phone}`} className="btn red">
            {contacts.phone}
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;

const Burger = ({ onClick }) => (
  <div className="burger" {...{ onClick }}>
    <span />
    <span />
    <span />
  </div>
);

const CloseButton = ({ onClick }) => <div className="close" {...{ onClick }} />;
