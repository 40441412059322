import { Link } from 'react-router-dom';

import contacts from '../../static/contacts';

import './cta.scss';

const CallToAction = () => (
  <section className="cta">
    <div className="container">
      <div className="cta__inner">
        <p>
          Enjoy <span /> Off Labor Costs
          <br /> Exclusively for Our Valued Regular Customers!
        </p>

        <div className="buttons">
          <Link to="/book-now" className="btn blue">
            Book Now
          </Link>

          <Link to={`tel:${contacts.phone}`} className="btn blue-border">
            {contacts.phone}
          </Link>
        </div>
      </div>
    </div>
  </section>
);

export default CallToAction;
