import './about.scss';

const About = ({ data }) => (
  <section className="home-about">
    <div className="container">
      <div className="home-about__inner">
        <div>
          <h4 className="section-title">{data.title}</h4>

          <div dangerouslySetInnerHTML={{ __html: data.text }} />
        </div>

        <div style={{ backgroundImage: `url(${data.image})` }} />
      </div>
    </div>
  </section>
);

export default About;
