import useFetch from 'react-fetch-hook';

import { ADMIN_URL } from '../App';

import { Layout, Seo, Spinner } from '../components';

import { CommonHero, AboutService, CallToAction, OurValue, CallUs } from '../sections';

const About = () => {
  const { data, error, isLoading } = useFetch(`${ADMIN_URL}/pages?slug=about`);

  if (isLoading) return <Spinner />;

  return (
    <Layout id="services">
      <Seo data={data[0]?.yoast_head_json} />
      <CommonHero title="About Us" />
      <AboutService data={data[0]?.acf} />
      <CallToAction />
      <OurValue data={data[0]?.acf.our_value} />
      <CallUs />
    </Layout>
  );
};

export default About;
