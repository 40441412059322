import contacts from '../../../static/contacts';

import './aboutServices.scss';

const AboutServices = ({ data }) => (
  <section className="home-about-services">
    <div className="container">
      <h1 className="section-title">{data.title}</h1>

      <h2 className="subtitle">{data.subtitle}</h2>

      <div className="home-about-services__content">
        <div dangerouslySetInnerHTML={{ __html: data.paragraph_1 }} />

        <div>
          <div dangerouslySetInnerHTML={{ __html: data.paragraph_2 }} />
        </div>
      </div>

      <div className="services__list">
        {data.services_list.map((el, index) => (
          <div key={index}>
            <span>{el.title}</span>
            <p>{el.description}</p>
          </div>
        ))}

        <div dangerouslySetInnerHTML={{ __html: data.notice }} className="notice" />

        <div className="give-a-call">
          <span>Give us a call at</span>
          <a href={`tel:${contacts.phone}`}>{contacts.phone} today!</a>
        </div>
      </div>
    </div>
  </section>
);

export default AboutServices;
