import { Link } from 'react-router-dom';

import './aboutService.scss';

const AboutService = ({ data, button = false, title }) => (
  <section className="about-service" id="about-services">
    <div className="container">
      <h2 className="section-title">{data.title}</h2>

      <div className="content-grid">
        <div dangerouslySetInnerHTML={{ __html: data.content.first_column }} />
        <div dangerouslySetInnerHTML={{ __html: data.content.second_column }} />

        {button && title && (
          <Link to="/book-now" className="btn red">
            Book {title} Service
          </Link>
        )}
      </div>
    </div>
  </section>
);

export default AboutService;
