import { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import useFetch from 'react-fetch-hook';
import 'react-toastify/dist/ReactToastify.css';

import { Header, Footer, AdditionalMenu } from '../';

import contacts from '../../static/contacts';

import './layout.scss';

const HEADER_NAVIGATION_URL = 'https://admin.prothermalcali.com/wp-json/menus/v1/menus/main-menu';

const Layout = ({ id, children }) => {
  const [isVisible, setVisibility] = useState(false);

  const header_navigation = useFetch(HEADER_NAVIGATION_URL);

  const handleScroll = () => {
    setVisibility(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [id]);

  return (
    <div>
      <Header menu={header_navigation.data} />
      <main id={id}>{children}</main>
      <Footer menu={header_navigation.data} />
      <ToastContainer />
      <AdditionalMenu />
      <div className={`mobile-cta-buttons ${isVisible ? 'on' : 'off'}`}>
        <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
        <Link to="/book-now">Book Now</Link>
      </div>
    </div>
  );
};

export default Layout;
